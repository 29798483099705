import { Injectable } from "@angular/core";
import { ApiService } from "@core/services/api.service";
import { StateService } from "@core/services/state.service";
import { AuthenticationService } from "app/auth/service";
import { environment } from "environments/environment";
import {
  BehaviorSubject,
  of,
  Observable,
  throwError,
  forkJoin,
  combineLatest,
} from "rxjs";
import {
  switchMap,
  take,
  tap,
  catchError,
  map,
  withLatestFrom,
  mergeMap,
} from "rxjs/operators";
import { Column, Penznem } from "../models/torzsek";
import { findByProp } from "../utils";
import { PartnerekService } from "./partnerek.service";
import { CikktorzsService } from "./cikktorzs.service";
import { PenznemekService } from "./penznemek.service";
import { ArkategoriakService } from "./arkategoriak.service";
import { NgxIndexedDBService } from "ngx-indexed-db";
import { AfakulcsokService } from "./afakulcsok.service";

@Injectable({
  providedIn: "root",
})
export class AjanlatService {
  private readonly apiEndpoint = "ajanlat";
  private readonly nyomtatasEndpoint = "arajanlat";
  private readonly columnsEndpoint = "ajanlat/columns";
  private readonly updateEndpoint = "service/helplib/bizonylat_frissites/a";
  private readonly nyomtatasBeallitasEndpoint =
    "service/helplib/nyomtatas_kapcsolo/a";
  private lastFetch: Date = new Date();

  private readonly _data = new BehaviorSubject<any[]>(null);
  private readonly _selectedData = new BehaviorSubject<any>(null);

  // private state$ = combineLatest([
  //   this._data,
  //   this.partnerekService.data$,
  //   this.penznemekService.data$,
  //   this.arkategoriakService.data$,
  // ]).pipe(
  //   switchMap(([state, partnerek, penznemek, arkategoriak]) => {
  //     return state
  //       ? of(this.mapAjanlat(state, partnerek, penznemek, arkategoriak))
  //       : this.ajanlatok?.db > 0
  //       ? this.getDBData()
  //       : this.fetchData();
  //   })
  // );

  private state$ = combineLatest([
    this._data,
    this.partnerekService.data$,
    this.penznemekService.data$,
    this.arkategoriakService.data$,
  ]).pipe(
    switchMap(([state, partnerek, penznemek, arkategoriak]) =>
      state
        ? of(this.mapAjanlat(state, partnerek, penznemek, arkategoriak))
        : this.fetchData()
    )
  );

  public data$ = combineLatest([
    this.state$,
    this.authService.currentUser$,
  ]).pipe(map(([data, user]) => data.filter((e: any) => e.oe_id === user.oe)));

  constructor(
    private authService: AuthenticationService,
    private partnerekService: PartnerekService,
    private api: ApiService,
    private stateService: StateService,
    private cikktorzsService: CikktorzsService,
    private penznemekService: PenznemekService,
    private arkategoriakService: ArkategoriakService,
    private afakulcsokService: AfakulcsokService,
    private dbService: NgxIndexedDBService
  ) {}

  get columns$(): Observable<Column[]> {
    return this.getColumns();
  }

  // get initData$(): Observable<any[]> {
  //   return this._data.pipe(
  //     take(1),
  //     switchMap((state: any[]) =>
  //       state
  //         ? forkJoin([
  //             of(state),
  //             this.partnerekService.initData$,
  //             this.penznemekService.initData$,
  //             this.arkategoriakService.initData$,
  //           ]).pipe(
  //             map(([ajanlatok, partnerek, penznemek, arkategoriak]) =>
  //               this.mapAjanlat(ajanlatok, partnerek, penznemek, arkategoriak)
  //             )
  //           )
  //         : JSON.parse(localStorage.getItem("ajanlat"))?.db
  //         ? this.getDBData()
  //         : this.fetchData()
  //     ),
  //     withLatestFrom(this.authService.currentUser$),
  //     map(([data, user]) => data.filter((e: any) => e.oe_id === user.oe))
  //   );
  // }

  get initData$(): Observable<any[]> {
    return this._data.pipe(
      take(1),
      switchMap((state: any[]) =>
        state
          ? forkJoin([
              of(state),
              this.partnerekService.initData$,
              this.penznemekService.initData$,
              this.arkategoriakService.initData$,
            ]).pipe(
              map(([ajanlatok, partnerek, penznemek, arkategoriak]) =>
                this.mapAjanlat(ajanlatok, partnerek, penznemek, arkategoriak)
              )
            )
          : this.fetchData()
      ),
      withLatestFrom(this.authService.currentUser$),
      map(([data, user]) => data.filter((e: any) => e.oe_id === user.oe))
    );
  }

  add(data: any): Observable<any> {
    return this.api.post(this.apiEndpoint, this.toJSON(data)).pipe(
      tap((added: any) => {
        if (data.foglalas && data.statusz == 1) {
          this.cikktorzsService.setCikktorzsState(data.tetelek);
        }
        this.stateService.setStateOnAdd(added, this._data);
      }),
      catchError((err: any) => throwError(err))
    );
  }

  delete(id: number): Observable<any> {
    return this.api.delete(this.apiEndpoint, id, "sztornózás").pipe(
      tap((storno: any) => {
        this.stateService.setStateOnDelete(storno, this._data);
      }),
      catchError((err: any) => throwError(err))
    );
  }

  sztorno(id: number): Observable<any> {
    return this.api.delete(this.apiEndpoint, id, "sztornózás").pipe(
      tap((storno: any) => {
        this.stateService.setStateOnUpdate(storno, this._data);
      }),
      catchError((err: any) => throwError(err))
    );
  }

  getById(id: string): Observable<any> {
    return this.data$.pipe(
      take(1),
      map((state) => {
        // Find the data
        const data = state.find((item: any) => item.id === +id) || null;

        // Update the data
        this._selectedData.next(data);

        // Return the data
        return data;
      }),
      switchMap((data) => {
        if (id === "new") {
          return of(null);
        }

        if (!data) {
          return throwError("Could not found data with id of " + id + "!");
        }

        return of({
          ...data,
          ervenyessegi_datum: new Date(data.ervenyessegi_datum),
          bizonylat_datuma: new Date(data.bizonylat_datuma),
        });
      })
    );
  }

  fetchAndMapDataById(id: number | string): Observable<any> {
    // Find the data in state
    const data = this._data.value.find((item: any) => item.id === +id) || null;

    // Update the data
    if(data.tetelek) {
      this._selectedData.next(data);
    }

    return data.tetelek ? of(data) : forkJoin([
      this.api.get(`${this.apiEndpoint}/${id}`).pipe(
        catchError((err: any) => {
          console.log(err);
          return of(null);
        })
      ),
      this.partnerekService.initData$,
      this.penznemekService.initData$,
      this.arkategoriakService.initData$,
      this.afakulcsokService.initData$
    ]).pipe(
      map(([ajanlat, partnerek, penznemek, arkategoriak, afakulcsok]) => {
        const retVal = this.mapAjanlatById(ajanlat, partnerek, penznemek, arkategoriak, afakulcsok)
        this.stateService.setStateOnUpdate(retVal, this._data);
        this._selectedData.next(retVal);
        return retVal;
      }
      )
    );
  }

  update(data: any): Observable<any> {
    return this.api
      .put(`${this.apiEndpoint}/${data.id}`, this.toJSON(data))
      .pipe(
        tap((updated: any) => {
          this.stateService.setStateOnUpdate(updated, this._data);
        }),
        catchError((err: any) => throwError(err))
      );
  }

  getBizonylatValtozas(): any {
    this.lastFetch = new Date();
    const oe = JSON.parse(localStorage.getItem("currentUser")).oe;
    const last = JSON.parse(localStorage.getItem("ajanlat"))?.lastFetch;
    return this.api
      .postBizonylat(`${this.updateEndpoint}/${oe}/${last}`, "")
      .pipe(
        take(1),
        tap((valtozas) => {
          if (this._data.value.length == 0) {
            //this.fetchData().subscribe();
          } else {
            if (valtozas.valtozasok.length > 0) {
              valtozas.valtozasok.forEach((bizonylat) => {
                const biz = findByProp(this._data.value, "id", bizonylat.id);
                if (biz) {
                  this.stateService.setStateOnUpdate(bizonylat, this._data);
                } else {
                  this.stateService.setStateOnAdd(bizonylat, this._data);
                }
              });
            }
            if (valtozas.torolt_lista.length > 0) {
              valtozas.torolt_lista.forEach((bizonylat) => {
                const biz = findByProp(
                  this._data.value,
                  "id",
                  bizonylat.modul_id
                );
                if (biz) {
                  this.stateService.setStateOnDelete(biz, this._data);
                }
              });
            }
          }

          localStorage.setItem(
            "ajanlat",
            JSON.stringify({
              db: this._data.value.length,
              lastFetch: this.lastFetch,
            })
          );
        })
      );
  }

  fetchData(): Observable<any[]> {
    this.lastFetch = new Date();
    return forkJoin([
      this.api.get(`${this.apiEndpoint}`).pipe(
        tap((data: any[]) => {
          this._data.next(data);
        }),
        catchError((err: any) => {
          console.log(err);
          return of([]);
        })
      ),
      this.partnerekService.initData$,
      this.penznemekService.initData$,
      this.arkategoriakService.initData$,
    ]).pipe(
      map(([ajanlatok, partnerek, penznemek, arkategoriak]) => {
        localStorage.setItem(
          "ajanlat",
          JSON.stringify({ db: ajanlatok.length, lastFetch: this.lastFetch })
        );
        return this.mapAjanlat(ajanlatok, partnerek, penznemek, arkategoriak);
      })
    );
  }

  // private getColumns(): Observable<Column[]> {
  //   return this.api.get(this.columnsEndpoint).pipe(
  //     map((columns: Column[]) => [
  //       ...columns,
  //       { name: 'cim', nullable: true },
  //       { name: 'tetelek', nullable: false, hidden: true },
  //       { name: 'megjegyzes_nyomtat', nullable: false, hidden: true, default: 1 },
  //     ]),
  //     catchError((err: any) => {
  //       console.log(err);
  //       return of([]);
  //     })
  //   );
  // }

  private fetchDataById(id: number): Observable<any> {
    return this.api.get(`${this.apiEndpoint}/${id}`).pipe(
      catchError((err: any) => {
        console.log(err);
        return of(null);
      })
    );
  }

  updateBizonylatRow(id: number): Observable<any> {
    return this.fetchDataById(id).pipe(
      tap((bizonylat) => {
        this.stateService.setStateOnUpdate(bizonylat, this._data);
      })
    );
  }

  private getColumns(): Observable<Column[]> {
    const columns = JSON.parse(localStorage.getItem("ajanlat_columns"));

    if (columns) {
      return of(columns);
    } else {
      return this.api.get(this.columnsEndpoint).pipe(
        map((columns: Column[]) => {
          const mappedColumns = [
            ...columns,
            { name: "cim", nullable: true },
            { name: "tetelek", nullable: false, hidden: true },
            {
              name: "megjegyzes_nyomtat",
              nullable: false,
              hidden: true,
              default: 1,
            },
          ];
          localStorage.setItem(
            "ajanlat_columns",
            JSON.stringify(mappedColumns)
          );
          return mappedColumns;
        }),
        catchError((err: any) => {
          console.log(err);
          return of([]);
        })
      );
    }
  }

  getDBData(): Observable<any> {
    console.log("get ajanlatok from indexedDB");
    // return this.dbService.getAll('ajanlat').pipe(
    //   tap((data: any[]) => this._data.next(data.sort((a,b) => b.id - a.id))),
    // );
    return forkJoin([
      this.dbService
        .getAll("ajanlat")
        .pipe(
          tap((data: any[]) =>
            this._data.next(data.sort((a, b) => b.id - a.id))
          )
        ),
      this.partnerekService.initData$,
      this.penznemekService.initData$,
      this.arkategoriakService.initData$,
    ]).pipe(
      map(([ajanlatok, partnerek, penznemek, arkategoriak]) => {
        return this.mapAjanlat(ajanlatok, partnerek, penznemek, arkategoriak);
      })
    );
  }

  private mapAjanlat(
    ajanlatok: any[],
    partnerek: any[],
    penznemek: any[],
    arkategoriak: any[]
  ): any[] {
    return ajanlatok.map((e: any) => {
      const penznemObj: Penznem = findByProp(penznemek, "id", e.penznem_id);

      const penznem = penznemObj?.outfix || penznemObj?.prefix;

      return {
        ...e,
        partner: findByProp(partnerek, "id", e.partner_id)?.nev,
        bizonylat_datuma: new Date(e.bizonylat_datuma),
        ervenyessegi_datum: new Date(e.ervenyessegi_datum),
        penznem,
        // tetelek: e.tetelek?.map((t: any) => ({
        //   ...t,
        //   penznem,
        //   arkategoria: findByProp(arkategoriak, "id", t.arkategoria_id)?.nev,
        // })),
      };
    });
  }

  private mapAjanlatById(
    ajanlat: any,
    partnerek: any[],
    penznemek: any[],
    arkategoriak: any[],
    afakulcsok: any[]
  ): any[] {
      const penznemObj: Penznem = findByProp(penznemek, "id", ajanlat.penznem_id);

      const penznem = penznemObj?.outfix || penznemObj?.prefix;

      return {
        ...ajanlat,
        partner: findByProp(partnerek, "id", ajanlat.partner_id)?.nev,
        bizonylat_datuma: new Date(ajanlat.bizonylat_datuma),
        ervenyessegi_datum: new Date(ajanlat.ervenyessegi_datum),
        penznem,
        tetelek: ajanlat.tetelek?.map((t: any) => ({
          ...t,
          penznem,
          arkategoria: findByProp(arkategoriak, "id", t.arkategoria_id)?.nev,
          afakulcs_nev: findByProp(afakulcsok, 'id', t.afakulcs_id)?.nev 
        })),
      };
  }

  private toJSON(data: any): any {
    return {
      ...data,
      cim: JSON.stringify(data.cim),
    };
  }

  generatePdf(payload: { id: number; sztorno?: boolean }): Observable<any> {
    return this.api
      .postBizonylat(
        `${environment.pdfGeneralas}/${this.nyomtatasEndpoint}`,
        payload
      )
      .pipe(
        map(({ url }) => `${environment.uploads}${url}`),
        catchError(() => of(null))
      );
  }

  nyomtatasBeallitas(
    row: any,
    megjegyzes_nyomtat: number,
    hivatkozas_nyomtat: number = 0
  ): Observable<any> {
    return this.api
      .post(
        `${this.nyomtatasBeallitasEndpoint}/${row.id}/${megjegyzes_nyomtat}/${hivatkozas_nyomtat}`,
        ""
      )
      .pipe(
        tap((updated: any) => {
          const updateRow = {
            ...row,
            megjegyzes_nyomtat: megjegyzes_nyomtat,
          };
          this.stateService.setStateOnUpdate(updateRow, this._data);
        }),
        catchError(() => of(null))
      );
  }
}
