import { Column } from '../models/torzsek';

/* eslint-disable @typescript-eslint/naming-convention */
export const alapadatokColumns: Column[] = [
  { name: 'nev', type: null, max_length: null, nullable: false },
  { name: 'ugyfel_tipus', type: null, max_length: null, nullable: false },
  { name: 'telefonszam', type: null, max_length: null, nullable: true },
  { name: 'adoszam', type: null, max_length: null, nullable: false },
  { name: 'eu_adoszam', type: null, max_length: null, nullable: false },
  {
    name: 'cegjegyzekszam,',
    type: null,
    max_length: null,
    nullable: false,
  },
  { name: 'email', type: null, max_length: null, nullable: false },
  { name: 'telefonszam', type: null, max_length: null, nullable: false },
  { name: 'penznem_id', type: null, max_length: null, nullable: false },
  { name: 'fizetesimod_id', type: null, max_length: null, nullable: false },
  { name: 'afakulcs_id', type: null, max_length: null, nullable: false },
  { name: 'forgalom', type: null, max_length: null, nullable: false },
  {
    name: 'alkalmazotti_letszam',
    type: null,
    max_length: null,
    nullable: false,
  },
];

export const bankszamlakColumns: Column[] = [
  {
    name: 'id',
    type: null,
    max_length: null,
    nullable: true,
    hidden: true,
  },
  {
    name: 'szamlatulajdonos',
    type: null,
    max_length: null,
    nullable: false,
  },
  { name: 'penzintezet_id', type: null, max_length: null, nullable: false },
  { name: 'penzintezet', type: null, max_length: null, nullable: true },
  { name: 'szla', type: null, max_length: null, nullable: false },
  {
    name: 'kulfoldi_szla',
    type: 'tinyint',
    max_length: null,
    nullable: true,
  },
  { name: 'penznem_id', type: null, max_length: null, nullable: false },
  { name: 'iban', type: null, max_length: null, nullable: true },
  { name: 'swift', type: null, max_length: null, nullable: true },
];

export const cimekColumns: Column[] = [
  { name: 'cimtipus', type: null, max_length: null, nullable: false },
  { name: 'orszag', type: null, max_length: null, nullable: true },
  { name: 'megye', type: null, max_length: null, nullable: true },
  { name: 'iranyitoszam', type: null, max_length: null, nullable: false },
  { name: 'telepules', type: null, max_length: null, nullable: false },
  {
    name: 'kozterulet_neve',
    type: null,
    max_length: null,
    nullable: false,
  },
  {
    name: 'kozterulet_tipusa',
    type: null,
    max_length: null,
    nullable: false,
  },
  { name: 'hazszam', type: null, max_length: null, nullable: false },
  { name: 'epulet', type: null, max_length: null, nullable: true },
  { name: 'emelet', type: null, max_length: null, nullable: true },
  { name: 'ajto', type: null, max_length: null, nullable: true },
  { name: 'fullCim', type: null, max_length: null, nullable: true },
];

export const shortCimekColumns: Column[] = [
  { name: 'kulfoldi', nullable: true },
  {
    name: 'cimtipus',
    type: null,
    max_length: null,
    nullable: false,
    default: 'Székhely',
  },
  { name: 'orszag', type: null, max_length: null, nullable: false },
  { name: 'iranyitoszam', type: null, max_length: null, nullable: false },
  { name: 'telepules', type: null, max_length: null, nullable: false },
  {
    name: 'kozterulet_neve',
    type: null,
    max_length: null,
    nullable: false,
  },
  {
    name: 'kozterulet_tipusa',
    type: null,
    max_length: null,
    nullable: false,
  },
  { name: 'hazszam', type: null, max_length: null, nullable: false },
  { name: 'epulet', type: null, max_length: null, nullable: true },
  { name: 'emelet', type: null, max_length: null, nullable: true },
  { name: 'ajto', type: null, max_length: null, nullable: true },
];

export const ugyintezokColumns: Column[] = [
  {
    name: 'nev',
    type: null,
    max_length: null,
    nullable: false,
  },
  {
    name: 'email',
    type: null,
    max_length: null,
    nullable: false,
  },
  {
    name: 'telefonszam',
    type: null,
    max_length: null,
    nullable: true,
  },
  {
    name: 'megjegyzes',
    type: null,
    max_length: null,
    nullable: true,
  },
];

export const AjanlatColumns: Column[] = [
  // {
  //   name: 'id',
  //   nullable: true,
  // },
  {
    name: 'bizonylat_szam',
    nullable: true,
  },
  {
    name: 'partner',
    nullable: true,
  },
  {
    name: 'bizonylat_datuma',
    nullable: true,
    type: 'date',
  },
  {
    name: 'ervenyessegi_datum',
    nullable: true,
    type: 'date',
  },
  {
    name: 'netto_ar',
    type: 'currency',
    nullable: true,
    suffix: 'Ft',
  },
  {
    name: 'afa_ar',
    type: 'currency',
    nullable: true,
    suffix: 'Ft',
  },
  {
    name: 'brutto_ar',
    type: 'currency',
    nullable: true,
    suffix: 'Ft',
  },
  {
    name: 'megjegyzes',
    type: 'text',
    nullable: true,
  },
];

export const FoglalasColumns: Column[] = [
  // {
  //   name: 'id',
  //   nullable: true,
  // },
  {
    name: 'bizonylat_szam',
    nullable: true,
  },
  {
    name: 'partner',
    nullable: true,
  },
  {
    name: 'bizonylat_datuma',
    nullable: true,
    type: 'date',
  },
  {
    name: 'ervenyessegi_datum',
    nullable: true,
    type: 'date',
  },
  {
    name: 'netto_ar',
    type: 'currency',
    nullable: true,
    suffix: 'Ft',
  },
  {
    name: 'afa_ar',
    type: 'currency',
    nullable: true,
    suffix: 'Ft',
  },
  {
    name: 'brutto_ar',
    type: 'currency',
    nullable: true,
    suffix: 'Ft',
  }
];

export const EgyebRaktariColumns: Column[] = [
  {
    name: 'id',
    nullable: true,
  },
  {
    name: 'oe_id',
    nullable: true,
  },
  {
    name: 'raktar_id',
    nullable: true,
  },
  {
    name: 'letrehozva',
    nullable: true,
    type: 'date',
  },
  {
    name: 'megjegyzes',
    nullable: true,
  }
];

export const BejovoSzamlaColumns: Column[] = [
  // {
  //   name: 'id',
  //   nullable: true,
  // },
  {
    name: 'iktatasi_szam',
    nullable: true,
    width: '150px'
  },
  {
    name: 'partner',
    nullable: true,
    width: '280px'
  },
  {
    name: 'szamlasorszam',
    title: 'Számla sorszám',
    nullable: true,
    width: '150px'
  },
  {
    name: 'szamla_kelte',
    nullable: true,
    type: 'date',
    width: '130px'
  },
  {
    name: 'teljesites_datum',
    nullable: true,
    type: 'date',
    width: '130px'
  },
  {
    name: 'fizetesi_hatarido',
    nullable: true,
    type: 'date',
    width: '130px'
  },
  {
    name: 'netto_ar',
    type: 'currency',
    nullable: true,
    suffix: 'Ft',
    width: '130px'
  },
  {
    name: 'afa_ar',
    type: 'currency',
    nullable: true,
    suffix: 'Ft',
    width: '130px'
  },
  {
    name: 'brutto_ar',
    type: 'currency',
    nullable: true,
    suffix: 'Ft',
    width: '130px'
  },
  {
    name: 'skonto_datum',
    nullable: true,
    type: 'date',
    width: '130px'
  },
  {
    name: 'skonto',
    type: 'text',
    nullable: true,
    suffix: '%',
    width: '130px'
  },
];

export const BeszerzesColumns: Column[] = [
  // {
  //   name: 'id',
  //   nullable: true,
  // },
  {
    name: 'partner',
    nullable: true,
  },
  {
    name: 'datum',
    nullable: true,
    type: 'date',
  },
  {
    name: 'tartalom',
    type: 'text',
    nullable: true
  },
];

export const NyugtaColumns: Column[] = [
  // {
  //   name: 'id',
  //   nullable: true,
  // },
  {
    name: 'bizonylat_szam',
    nullable: true,
  },
  {
    name: 'szamla_kelte',
    nullable: true,
    title: 'Nyugta kelte',
    type: 'date',
  },
  {
    name: 'netto_ar',
    type: 'currency',
    nullable: true,
    suffix: 'Ft',
  },
  {
    name: 'afa_ar',
    type: 'currency',
    nullable: true,
    suffix: 'Ft',
  },
  {
    name: 'brutto_ar',
    type: 'currency',
    nullable: true,
    suffix: 'Ft',
  },
];

export const PenzugyiBizonylatokColumns: Column[] = [
  {
    name: 'nav_uzenet',
    nullable: true,
    width: '110px',
  },
  {
    name: 'bizonylat_szam',
    nullable: true,
    width: '140px',
  },
  {
    name: 'partner',
    nullable: true,
    width: '288px',
  },
  {
    name: 'szamla_kelte',
    nullable: true,
    type: 'date',
    width: '140px',
  },
  {
    name: 'teljesites_datum',
    nullable: true,
    type: 'date',
    width: '140px',
  },
  {
    name: 'fizetesi_hatarido',
    nullable: true,
    type: 'date',
    width: '167px',
  },
  {
    name: 'netto_ar',
    type: 'currency',
    nullable: true,
    suffix: 'Ft',
    width: '140px',
  },
  {
    name: 'afa_ar',
    type: 'currency',
    nullable: true,
    suffix: 'Ft',
    width: '140px',
  },
  {
    name: 'brutto_ar',
    type: 'currency',
    nullable: true,
    suffix: 'Ft',
    width: '140px',
  },
  {
    name: 'fizetesiMod',
    type: 'text',
    nullable: true,
    width: '180px',
  },
];

export const DijbekeroColumns: Column[] = [
  {
    name: 'bizonylat_szam',
    nullable: true,
    width: '140px',
  },
  {
    name: 'partner',
    nullable: true,
    width: '288px',
  },
  {
    name: 'szamla_kelte',
    nullable: true,
    type: 'date',
    width: '140px',
  },
  {
    name: 'teljesites_datum',
    nullable: true,
    type: 'date',
    width: '140px',
  },
  {
    name: 'fizetesi_hatarido',
    nullable: true,
    type: 'date',
    width: '167px',
  },
  {
    name: 'netto_ar',
    type: 'currency',
    nullable: true,
    suffix: 'Ft',
    width: '140px',
  },
  {
    name: 'afa_ar',
    type: 'currency',
    nullable: true,
    suffix: 'Ft',
    width: '140px',
  },
  {
    name: 'brutto_ar',
    type: 'currency',
    nullable: true,
    suffix: 'Ft',
    width: '140px',
  },
  {
    name: 'fizetesiMod',
    type: 'text',
    nullable: true,
    width: '180px',
  },
];

export const PenzugyiBizonylatokDijbekeroColumns: Column[] = [
  {
    name: 'nav_uzenet',
    nullable: true,
    width: '110px',
  },
  {
    name: 'bizonylat_szam',
    nullable: true,
    width: '140px',
  },
  {
    name: 'partner',
    nullable: true,
    width: '288px',
  },
  {
    name: 'szamla_kelte',
    nullable: true,
    type: 'date',
    width: '140px',
  },
  {
    name: 'teljesites_datum',
    nullable: true,
    type: 'date',
    width: '140px',
  },
  {
    name: 'fizetesi_hatarido',
    nullable: true,
    type: 'date',
    width: '167px',
  },
  {
    name: 'netto_ar',
    type: 'currency',
    nullable: true,
    suffix: 'Ft',
    width: '140px',
  },
  {
    name: 'afa_ar',
    type: 'currency',
    nullable: true,
    suffix: 'Ft',
    width: '140px',
  },
  {
    name: 'brutto_ar',
    type: 'currency',
    nullable: true,
    suffix: 'Ft',
    width: '140px',
  }
];

export const PenzugyiBizonylatokListDetailsColumns: Column[] = [
  {
    name: 'cikknev',
    nullable: true,
  },
  {
    name: 'mennyiseg',
    type: 'number',
    nullable: false,
    suffixProp: 'mennyisegi_egyseg_text',
  },
  {
    name: 'netto_egysegar',
    type: 'currency',
    nullable: true,
    suffix: 'Ft',
  },
  {
    name: 'brutto_egysegar',
    type: 'currency',
    nullable: true,
    suffix: 'Ft',
  },
  {
    name: 'netto_ar',
    type: 'currency',
    nullable: true,
    suffix: 'Ft',
  },
  {
    name: 'afa_ar',
    type: 'currency',
    nullable: true,
    suffix: 'Ft',
  },
  {
    name: 'brutto_ar',
    type: 'currency',
    nullable: true,
    suffix: 'Ft',
  },
];

export const PenzugyiBizonylatokListDetailsColumnsWithArkategoria: Column[] = [
  {
    name: 'cikknev',
    nullable: true,
  },
  {
    name: 'arkategoria',
    title: 'Árkategória',
    nullable: true,
  },
  {
    name: 'afakulcs_nev',
    title: 'Áfakulcs',
    nullable: true,
  },
  {
    name: 'mennyiseg',
    type: 'number',
    nullable: false,
    suffixProp: 'mennyisegi_egyseg_text',
  },
  {
    name: 'netto_egysegar',
    type: 'currency',
    nullable: true,
    suffix: 'Ft',
  },
  {
    name: 'brutto_egysegar',
    type: 'currency',
    nullable: true,
    suffix: 'Ft',
  },
  {
    name: 'netto_ar',
    type: 'currency',
    nullable: true,
    suffix: 'Ft',
  },
  {
    name: 'afa_ar',
    type: 'currency',
    nullable: true,
    suffix: 'Ft',
  },
  {
    name: 'brutto_ar',
    type: 'currency',
    nullable: true,
    suffix: 'Ft',
  },
];

export const KoltsegListDetailsColumns: Column[] = [
  {
    name: 'cikknev',
    nullable: true,
  },
  {
    name: 'mennyiseg',
    type: 'number',
    nullable: false,
    suffixProp: 'mennyisegi_egyseg_text',
  },
  {
    name: 'netto_egysegar',
    type: 'currency',
    nullable: true,
    suffix: 'Ft',
  },
  {
    name: 'brutto_egysegar',
    type: 'currency',
    nullable: true,
    suffix: 'Ft',
  },
  {
    name: 'netto_ar',
    type: 'currency',
    nullable: true,
    suffix: 'Ft',
  },
  {
    name: 'afa_ar',
    type: 'currency',
    nullable: true,
    suffix: 'Ft',
  },
  {
    name: 'brutto_ar',
    type: 'currency',
    nullable: true,
    suffix: 'Ft',
  },
];

export const SzallitolevelColumns: Column[] = [
  // {
  //   name: 'id',
  //   nullable: true,
  // },
  {
    name: 'bizonylat_szam',
    nullable: true,
  },
  {
    name: 'partner',
    nullable: true,
  },
  {
    name: 'teljesites_datum',
    title: 'Szállítás dátuma',
    nullable: true,
    type: 'date',
  },
  {
    name: 'bizonylat_datuma',
    nullable: true,
    type: 'date',
  },
  {
    name: 'megjegyzes',
    nullable: true,
    type: 'text',
  },
];

export const UtalasiJegyzekDetailsColumns: Column[] = [
  { name: 'partner', nullable: true, title: 'Kedvezményezett' },
  { name: 'bankszamlaszam', nullable: true, title: 'Bankszámlaszám' },
  { name: 'koltsegkategoria', nullable: true, title: 'Típus' },
  { name: 'kozlemeny', nullable: true, title: 'Közlemény' },
  {
    name: 'fizetendo',
    nullable: true,
    type: 'currency',
    suffixProp: 'penznem',
    title: 'Összeg',
  },
];

export const KintlevosegekDetailsColumns: Column[] = [
  { name: 'bizonylat_szam', width: '140px' },
  { name: 'szamla_kelte', width: '100px' },
  { name: 'fizetesi_hatarido', width: '120px' },
  {
    name: 'brutto_ar',
    type: 'currency',
    suffixProp: 'penznem',
    width: '100px',
  },
  {
    name: 'fennmarado',
    type: 'currency',
    suffixProp: 'penznem',
    width: '100px',
  },
  {
    name: 'kamat',
    type: 'currency',
    suffixProp: 'penznem',
    width: '100px',
  },
  {
    name: 'fizetendo',
    type: 'currency',
    suffixProp: 'penznem',
    width: '100px',
  },
  {
    name: 'fizmod',
    width: '100px',
  },
  { name: 'kamat_nap', suffix: 'napja', width: '100px' },
  { name: 'felszolitva', suffix: 'alkalommal', width: '100px' },
];

export const KintlevosegekHelyesbitesColumns: Column[] = [
  { name: 'bizonylat_szam', width: '140px' },
  { name: 'szamla_kelte', width: '100px' },
  { name: 'fizetesi_hatarido', width: '120px' },
  {
    name: 'brutto_ar',
    type: 'currency',
    suffixProp: 'penznem',
    width: '100px',
  },
  {
    name: 'fizmod',
    width: '100px',
  }
];