import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { bankszamlakColumns } from '../constants/data';
import { Column } from '../models/torzsek';
import { tetelekRowRequiredValidator } from '../validators/tetelek.validator';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor(private fb: FormBuilder) {}

  createFormGroup(
    columns: Column[],
    data?: any,
    cimtipus:
      | 'multiple'
      | 'full'
      | 'short'
      | 'skip-cimtipus'
      | 'optional-validation' = 'full',
    torzs?: string
  ): FormGroup {
    const filters = [
      'id',
      'statusz',
      'szulo_id',
      'letrehozo_id',
      'letrehozva',
      'modosito_id',
      'modositva',
    ];
    const controls = {};

    let defaultValues = {};
    let disabledControls: string[] = [];

    columns.forEach((column: Column) => {
      const validators = [];

      if (!column.nullable && filters.indexOf(column.name) === -1) {
        validators.push(Validators.required);
      }

      if (column.name === 'email') {
        validators.push(Validators.email);
      }

      // Custom
      if (column.name === 'cim' || column.name === 'cimek') {
        switch (cimtipus) {
          case 'multiple': {
            controls[column.name] = this.fb.control([]);
            break;
          }

          case 'full': {
            controls[column.name] = this.fb.group({
              szallitasi_cim: this.fb.group({
                kulfoldi: [false],
                orszag: [null],
                iranyitoszam: [null, Validators.required],
                telepules: [null, Validators.required],
                kozterulet_neve: [null, Validators.required],
                kozterulet_tipusa: [null, Validators.required],
                hazszam: [null, Validators.required],
                epulet: [null],
                emelet: [null],
                ajto: [null],
              }),
              szamlazasi_cim: this.fb.group({
                kulfoldi: [false],
                orszag: [null],
                iranyitoszam: [null, Validators.required],
                telepules: [null, Validators.required],
                kozterulet_neve: [null, Validators.required],
                kozterulet_tipusa: [null, Validators.required],
                hazszam: [null, Validators.required],
                epulet: [null],
                emelet: [null],
                ajto: [null],
              }),
            });

            break;
          }

          case 'short': {
            controls[column.name] = this.fb.group({
              kulfoldi: [false],
              orszag: [null],
              cimtipus: [null, Validators.required],
              iranyitoszam: [null, Validators.required],
              telepules: [null, Validators.required],
              kozterulet_neve: [null, Validators.required],
              kozterulet_tipusa: [null, Validators.required],
              hazszam: [null, Validators.required],
              epulet: [null],
              emelet: [null],
              ajto: [null],
            });

            break;
          }

          case 'skip-cimtipus': {
            controls[column.name] = column.nullable
              ? this.fb.group({
                  kulfoldi: [false],
                  orszag: [null],
                  iranyitoszam: [null],
                  telepules: [null],
                  kozterulet_neve: [null],
                  kozterulet_tipusa: [null],
                  hazszam: [null],
                  epulet: [null],
                  emelet: [null],
                  ajto: [null],
                })
              : this.fb.group({
                  kulfoldi: [false],
                  orszag: [null],
                  iranyitoszam: [null, Validators.required],
                  telepules: [null, Validators.required],
                  kozterulet_neve: [null, Validators.required],
                  kozterulet_tipusa: [null, Validators.required],
                  hazszam: [null, Validators.required],
                  epulet: [null],
                  emelet: [null],
                  ajto: [null],
                });

            break;
          }

          case 'optional-validation': {
            controls[column.name] = this.fb.group({
              kulfoldi: [false],
              orszag: [null],
              iranyitoszam: [null],
              telepules: [null],
              kozterulet_neve: [null],
              kozterulet_tipusa: [null],
              hazszam: [null],
              epulet: [null],
              emelet: [null],
              ajto: [null],
            });

            break;
          }

          default: {
            controls[column.name] = this.fb.group({
              iranyitoszam: [null, Validators.required],
              telepules: [null, Validators.required],
            });
          }
        }
      }

      // Egy elemű bankszámla
      else if (column.name === 'bankszamla' && torzs != 'havi-berek') {
        controls[column.name] = this.createFormGroup(bankszamlakColumns);
      }

      else if (column.name === 'bankszamla' && torzs == 'havi-berek') {
        controls[column.name] = [null, Validators.required];
      }

      // Munkatársak Családi adatok JSON
      else if (column.name === 'csaladi_adatok') {
        controls[column.name] = this.fb.group({
          hazastars_neve: [null],
          hazastars_ado_azonosito: [null],
          eltartottak: [[]],
        });
      }

      // Tételek
      else if (column.name === 'tetelek') {
        controls[column.name] = this.fb.control(
          [],
          tetelekRowRequiredValidator()
        );
      }

      // Default
      else {
        controls[column.name] =
          column.type === 'tinyint'
            ? [false, validators]
            : column?.disabled
            ? [{ value: null, disabled: true }, Validators]
            : [null, validators];
      }

      // Set Default values
      if (column?.default) {
        defaultValues[column.name] = column.default;
      }

      // Disable control
      if (column.disabled) {
        disabledControls.push(column.name);
      }
    });

    const group = this.fb.group(controls);

    // Patch Default values
    if (Object.keys(defaultValues).length) {
      group.patchValue(defaultValues);
    }

    // Disable controls
    if (disabledControls.length) {
      disabledControls.forEach((c: string) => {
        group.get(c).disable();
      });
    }

    if (data) {
      group.patchValue(data);
    }

    return group;
  }

  createBankszamlakForm(data?: any): FormGroup {
    const group: FormGroup = this.fb.group({
      id: [null],
      kulfoldi_szla: [false, Validators.required],
      szamlatulajdonos: [null, Validators.required],
      penzintezet_id: [null, Validators.required],
      penzintezet_nev: [null, Validators.required],
      szamlaszam: [null, Validators.required],
      penznem_id: [1, Validators.required],
      iban: [null],
      swift: [null],
    });

    if (data) {
      group.patchValue(data);
    }

    return group;
  }
}
