import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { StateService } from '@core/services/state.service';
import { User } from 'app/auth/models';
import { AuthenticationService } from 'app/auth/service';
import {
  BehaviorSubject,
  of,
  Observable,
  throwError,
  combineLatest,
  forkJoin,
} from 'rxjs';
import {
  switchMap,
  take,
  tap,
  catchError,
  map,
  withLatestFrom,
} from 'rxjs/operators';
import { Column, Dolgozo, OnelszamoloEgyseg, Raktar } from '../models/torzsek';
import { findByProp, stringifyIfJSON } from '../utils';
import { FelhasznaloiCsoportokService } from './felhasznaloi-csoportok.service';
import { OnelszamoloEgysegekService } from './onelszamolo-egysegek.service';
import { RaktarakService } from './raktarak.service';
import { NgxIndexedDBService } from 'ngx-indexed-db';

@Injectable({
  providedIn: 'root',
})
export class DolgozokService {
  private readonly apiEndpoint = 'dolgozok';
  private readonly columnsEndpoint = 'dolgozok/columns';
  private readonly dolgozoUpdateEndpoint = 'service/helplib/dolgozo_frissites';
  private readonly visszaallitasEndpoint = 'service/torzslib/vissza_allitas';
  private lastFetch: Date = new Date();
  private dolgozok = JSON.parse(localStorage.getItem('dolgozok'));

  private readonly _data = new BehaviorSubject<Dolgozo[]>(null);
  private readonly _selectedData = new BehaviorSubject<Dolgozo>(null);

  private state$ = combineLatest([
    this._data,
    this.fCsoportokService.data$,
    this.oeService.data$,
    this.raktarakService.data$,
  ]).pipe(
    switchMap(([state, fCsoportok, oek, raktarak]) => {
      return state
        ? of(this.mapDolgozok(state, fCsoportok, oek, raktarak))
        : this.fetchData()
      })
  );

  // private state$ = combineLatest([
  //   this._data,
  //   this.fCsoportokService.data$,
  //   this.oeService.data$,
  //   this.raktarakService.data$,
  // ]).pipe(
  //   switchMap(([state, fCsoportok, oek, raktarak]) =>
  //     state
  //       ? of(this.mapDolgozok(state, fCsoportok, oek, raktarak))
  //       : this.fetchData()
  //   )
  // );

  public data$ = combineLatest([
    this.state$,
    this.authService.currentUser$,
  ]).pipe(
    map(([data, user]) =>
      data.filter((e: any) => e.alapertelmezett_oe_id === user?.oe)
    )
  );

  constructor(
    private api: ApiService,
    private fCsoportokService: FelhasznaloiCsoportokService,
    private oeService: OnelszamoloEgysegekService,
    private raktarakService: RaktarakService,
    private stateService: StateService,
    private authService: AuthenticationService,
    private dbService: NgxIndexedDBService
  ) {}

  get columns$(): Observable<Column[]> {
    return this.getColumns();
  }

  get initData$(): Observable<Dolgozo[]> {
    console.log('init called');
    return this._data.pipe(
      take(1),
      switchMap((state: Dolgozo[]) =>
        state
          ? forkJoin([
              of(state),
              this.fCsoportokService.initData$,
              this.oeService.initData$,
              this.raktarakService.initData$,
            ]).pipe(
              map(([dolgozok, fCsoportok, oek, raktarak]) =>
                this.mapDolgozok(dolgozok, fCsoportok, oek, raktarak)
              )
            )
          : this.fetchData()
      ),
      withLatestFrom(this.authService.currentUser$),
      map(([data, user]) =>
        data.filter((e: any) => e.alapertelmezett_oe_id === user?.oe)
      )
    );
  }

  // get initData$(): Observable<Dolgozo[]> {
  //   return this._data.pipe(
  //     take(1),
  //     switchMap((state: Dolgozo[]) =>
  //       state
  //         ? forkJoin([
  //             of(state),
  //             this.fCsoportokService.initData$,
  //             this.oeService.initData$,
  //             this.raktarakService.initData$,
  //           ]).pipe(
  //             map(([dolgozok, fCsoportok, oek, raktarak]) =>
  //               this.mapDolgozok(dolgozok, fCsoportok, oek, raktarak)
  //             )
  //           )
  //         : this.fetchData()
  //     ),
  //     withLatestFrom(this.authService.currentUser$),
  //     map(([data, user]) =>
  //       data.filter((e: any) => e.alapertelmezett_oe_id === user?.oe)
  //     )
  //   );
  // }

  add(data: Dolgozo): Observable<Dolgozo> {
    return this.api.post(this.apiEndpoint, this.toJSON(data)).pipe(
      tap((added: Dolgozo) => {
        this.stateService.setStateOnAdd(this.fromJSON(added), this._data);
        //this.dbService.add('dolgozok', this.fromJSON(added)).subscribe();
      }),
      catchError((err: any) => throwError(err))
    );
  }

  delete(id: number): Observable<Dolgozo> {
    return this.api.delete(this.apiEndpoint, id).pipe(
      tap((deleted: Dolgozo) => {
        this.stateService.setStateOnUpdate(deleted, this._data);
        //this.dbService.deleteByKey('dolgozok', deleted.id).subscribe();
      }),
      catchError((err: any) => throwError(err))
    );
  }

  visszaallitas(row: any): Observable<any> {
    return this.api.post(`${this.visszaallitasEndpoint}/dolgozok/${row.id}`, '').pipe(
      map((dolgozo: Dolgozo | Dolgozo[]) => { return this.fromJSON(dolgozo) }),
      tap((updated: Dolgozo) => {
        // Update state
        this.stateService.setStateOnUpdate(updated, this._data);
      }),
      catchError((err: any) => {
        console.log(err);
        return of([]);
      })
    )
  }

  getById(id: string): Observable<Dolgozo> {
    return this.data$.pipe(
      take(1),
      map((state) => {
        // if(state) {
          this.fetchDataByID(id).subscribe();
          // Find the data
          const data = state.find((item: Dolgozo) => item.id === +id) || null;

          // Update the data
          this._selectedData.next(data);

          // Return the data
          return data;
        // } 
        // else {
        //   this.fetchData().subscribe(dolgozok => {
        //       // Find the data
        //       const data = dolgozok.find((item: Dolgozo) => item.id === +id) || null;

        //       // Update the data
        //       this._selectedData.next(data);

        //       // Return the data
        //       return data;
        //       })
        // }
      }),
      switchMap((data: Dolgozo | null) => {
        if (id === 'new') {
          return of(null);
        }

        if (!data) {
          //return throwError('Could not found data with id of ' + id + '!');
          return of(null);
        }

        return of(data);
      })
    );
  }

  update(data: Dolgozo): Observable<Dolgozo> {
    return this.api
      .put(`${this.apiEndpoint}/${data.id}`, this.toJSON(data))
      .pipe(
        map((updateRes: Dolgozo) => this.fromJSON(updateRes)),
        tap((updated: Dolgozo) => {
          // Update state
          this.stateService.setStateOnUpdate(updated, this._data);
          //this.dbService.update('dolgozok', updated).subscribe();
        }),
        switchMap((updated: Dolgozo) =>
          // Logged in user updated
          updated.id === this.authService.currentUserValue?.id
            ? forkJoin([
                this.oeService.initData$,
                this.authService.currentUser$.pipe(take(1)),
                this.fCsoportokService.initData$,
              ]).pipe(
                tap(([oek, user, fCsoportok]) => {
                  // Update user state
                  const updatedUser: User = {
                    ...user,
                    nev: `${updated.vezeteknev} ${updated.keresztnev}`,
                    oe: updated.alapertelmezett_oe_id,
                    oeNev: findByProp(oek, 'id', updated.alapertelmezett_oe_id)
                      ?.megnevezes,
                    raktar: updated.alapertelmezett_raktar_id,
                    felhasznalonev: updated.felhasznalonev,
                    felhasznaloi_csoport: {
                      id: updated.felhasznaloi_csoport_id,
                      nev: findByProp(
                        fCsoportok,
                        'id',
                        updated.felhasznaloi_csoport_id
                      )?.nev,
                    },
                    oek: updated.oek as number[],
                    raktarak: updated.raktarak as number[],
                  };

                  // Update local state and localStorage
                  this.authService.currentUser = updatedUser;
                }),
                map(() => {
                  return updated;
                })
              )
            : // Other update
              of(updated)
        ),
        catchError((err: any) => throwError(err))
      );
  }

  updateNoToastr(data: Dolgozo): Observable<Dolgozo> {
    return this.api
      .put(`${this.apiEndpoint}/${data.id}`, this.toJSON(data), 'disable')
      .pipe(
        map((updateRes: Dolgozo) => this.fromJSON(updateRes)),
        tap((updated: Dolgozo) => {
          // Update state
          this.stateService.setStateOnUpdate(updated, this._data);
          this.dbService.update('dolgozok', updated).subscribe();
        }),
        switchMap((updated: Dolgozo) =>
          // Logged in user updated
          updated.id === this.authService.currentUserValue?.id
            ? forkJoin([
                this.oeService.initData$,
                this.authService.currentUser$.pipe(take(1)),
                this.fCsoportokService.initData$,
              ]).pipe(
                tap(([oek, user, fCsoportok]) => {
                  // Update user state
                  const updatedUser: User = {
                    ...user,
                    nev: `${updated.vezeteknev} ${updated.keresztnev}`,
                    oe: updated.alapertelmezett_oe_id,
                    oeNev: findByProp(oek, 'id', updated.alapertelmezett_oe_id)
                      ?.megnevezes,
                    raktar: updated.alapertelmezett_raktar_id,
                    felhasznalonev: updated.felhasznalonev,
                    felhasznaloi_csoport: {
                      id: updated.felhasznaloi_csoport_id,
                      nev: findByProp(
                        fCsoportok,
                        'id',
                        updated.felhasznaloi_csoport_id
                      )?.nev,
                    },
                    oek: updated.oek as number[],
                    raktarak: updated.raktarak as number[],
                  };

                  // Update local state and localStorage
                  this.authService.currentUser = updatedUser;
                }),
                map(() => {
                  return updated;
                })
              )
            : // Other update
              of(updated)
        ),
        catchError((err: any) => throwError(err))
      );
  }

  private fetchDataByID(id: string): Observable<Dolgozo | Dolgozo[]> {
    return this.api.get(`${this.apiEndpoint}/${id}`).pipe(
      map((dolgozo: Dolgozo | Dolgozo[]) => { return this.fromJSON(dolgozo) }),
      catchError((err: any) => {
        console.log(err);
        return of([]);
      })
    )
  } 

  fetchData(): Observable<Dolgozo[]> {
    this.lastFetch = new Date();
    return forkJoin([
      this.api.get(this.apiEndpoint).pipe(
        map((dolgozok: Dolgozo[]) =>
          dolgozok.map((d: Dolgozo) => this.fromJSON(d))
        ),
        tap((data: Dolgozo[]) => this._data.next(data)),
        catchError((err: any) => {
          console.log(err);
          return of([]);
        })
      ),
      this.fCsoportokService.initData$,
      this.oeService.initData$,
      this.raktarakService.initData$,
    ]).pipe(
      map(([dolgozok, fCsoportok, oek, raktarak]) => {
        this.dbService.clear('dolgozok').subscribe();
            this.dbService
              .bulkAdd('dolgozok', this.mapDolgozok(dolgozok, fCsoportok, oek, raktarak))
              .subscribe((result) => {
                localStorage.setItem('dolgozok', JSON.stringify({db: result.length, lastFetch: this.lastFetch}));
              }, error => console.log(error));
        return this.mapDolgozok(dolgozok, fCsoportok, oek, raktarak)
      })
    );
  }

  // fetchData(): Observable<Dolgozo[]> {
  //   this.lastFetch = new Date();
  //   return forkJoin([
  //     this.api.get(this.apiEndpoint).pipe(
  //       map((dolgozok: Dolgozo[]) =>
  //         dolgozok.map((d: Dolgozo) => this.fromJSON(d))
  //       ),
  //       tap((data: Dolgozo[]) => this._data.next(data)),
  //       catchError((err: any) => {
  //         console.log(err);
  //         return of([]);
  //       })
  //     ),
  //     this.fCsoportokService.initData$,
  //     this.oeService.initData$,
  //     this.raktarakService.initData$,
  //   ]).pipe(
  //     map(([dolgozok, fCsoportok, oek, raktarak]) => {
  //       // this.dbService.count('dolgozok').subscribe(count => {
  //       //   if(count == 0) {
  //       //     this.dbService
  //       //       .bulkAdd('dolgozok', this.mapDolgozok(dolgozok, fCsoportok, oek, raktarak))
  //       //       .subscribe((result) => {
  //       //         console.log('result: ', result);
  //       //       }, error => console.log(error));
  //       //   }
  //       //   localStorage.setItem('dolgozok', JSON.stringify({db: dolgozok.length, lastFetch: this.lastFetch}));
  //       //   //this.cikkek = count;
  //       // })
  //       localStorage.setItem('dolgozok', JSON.stringify({db: dolgozok.length, lastFetch: this.lastFetch}));

  //       return this.mapDolgozok(dolgozok, fCsoportok, oek, raktarak)
  //     })
  //   );
  // }

  private getColumns(): Observable<Column[]> {
    return this.api.get(this.columnsEndpoint).pipe(
      map((columnsRes: Column[]) => {
        const jelszoVisszallitas: Column = {
          name: 'jelszo_visszaallitas',
          nullable: true,
          hidden: true,
        };

        const ujJelszo: Column = {
          name: 'uj_jelszo',
          nullable: true,
          hidden: true,
        };

        const alapertelmezettOe: Column = {
          name: 'alapertelmezett_oe',
          nullable: true,
        };

        const alapertelmezettRaktar: Column = {
          name: 'alapertelmezett_raktar',
          nullable: true,
        };

        const fajlok: Column = {
          name: 'fajlok',
          nullable: true,
        };

        const columns = [
          ...columnsRes,
          alapertelmezettOe,
          alapertelmezettRaktar,
          jelszoVisszallitas,
          ujJelszo,
          fajlok
        ];

        const shownColumns = [
          'id',
          'vezeteknev',
          'keresztnev',
          'neme',
          'alapertelmezett_oe',
          'alapertelmezett_raktar',
          'felhasznalonev',
          'felhasznaloi_csoport',
          'utolso_bejelentkezes',
        ];

        const displayedColumns = columns.map((c: Column) =>
          shownColumns.includes(c.name) ? c : { ...c, hidden: true }
        );

        return displayedColumns;
      }),
      catchError((err: any) => {
        console.log(err);
        return of([]);
      })
    );
  }

  private mapDolgozok(
    dolgozok: any[],
    fCsoportok: any[],
    oek: OnelszamoloEgyseg[],
    raktarak: Raktar[]
  ): Dolgozo[] {
    return dolgozok.map((m: any) => ({
      ...m,
      felhasznaloi_csoport: findByProp(
        fCsoportok,
        'id',
        m.felhasznaloi_csoport_id
      )?.nev,
      alapertelmezett_oe: findByProp(oek, 'id', m.alapertelmezett_oe_id)
        ?.megnevezes,
      alapertelmezett_raktar: findByProp(
        raktarak,
        'id',
        m.alapertelmezett_raktar_id
      )?.megnevezes,
    }));
  }

  private fromJSON(dolgozo: Dolgozo | Dolgozo[]): Dolgozo | Dolgozo[] {
    if (Array.isArray(dolgozo)) {
      return dolgozo.map((dolgozo: Dolgozo) => ({
        ...dolgozo,
        oek: JSON.parse(dolgozo.oek as string),
        raktarak: JSON.parse(dolgozo.raktarak as string),
      })
      )
    } else {
      return {
        ...dolgozo,
        oek: JSON.parse(dolgozo.oek as string),
        raktarak: JSON.parse(dolgozo.raktarak as string),
      };
    }
  }

  private toJSON(dolgozo: Dolgozo): Dolgozo {
    return {
      ...dolgozo,
      oek: stringifyIfJSON(dolgozo.oek),
      raktarak: stringifyIfJSON(dolgozo.raktarak),
    };
  }


  getDBData(): Observable<any> {
    console.log('get dolgozok from indexedDB')
    return forkJoin([
      this.dbService.getAll('dolgozok').pipe(
        tap((data: any[]) => this._data.next(data.sort((a,b) => b.id - a.id))),
      ),
      this.fCsoportokService.initData$,
      this.oeService.initData$,
      this.raktarakService.initData$,
    ]).pipe(
      map(([dolgozok, fCsoportok, oek, raktarak]) => {
        return this.mapDolgozok(dolgozok, fCsoportok, oek, raktarak)
      })
    );
  }

  getDolgozoChange(): any {
    this.lastFetch = new Date();
    const last = JSON.parse(localStorage.getItem('dolgozok'))?.lastFetch;
    this.api.get(`${this.dolgozoUpdateEndpoint}/${last}`).pipe(
        // take(1),
        //tap((data: any[]) => this._data.next(data)),
        catchError((err: any) => {
          console.log(err);
          return of([]);
        })
    )
    .subscribe((resp: any) => {
      if(resp) {
        if(this._data.value) {
          if(resp.valtozas.length > 0) {
            resp.valtozas.forEach(dolgozo => {
              const currentUser = JSON.parse(localStorage.getItem('currentUser'));
              const cikkIndex = this._data.value?.findIndex(item => item.id == dolgozo.id);

              if(cikkIndex < 0) {
                this.stateService.setStateOnAdd(this.fromJSON(dolgozo), this._data);
                this.dbService.add('dolgozok', this.fromJSON(dolgozo)).subscribe();
              } else {
                this._data.value[cikkIndex] = {
                  ...dolgozo
                };
                this.dbService.update('dolgozok', this.fromJSON(dolgozo)).subscribe();
              }

              if(dolgozo.id == currentUser.id) {
                const parsed = {
                  ...currentUser,
                  jogosultsagok: JSON.parse(dolgozo.jogosultsagok)
                }

                this.authService.currentUser = parsed;
              }
            });
          }
          if(resp.torolt_lista.length > 0) {
            resp.torolt_lista.forEach(dolgozo => {
              const biz = findByProp(this._data.value, 'id', dolgozo.modul_id);
              if(biz) {
                this.stateService.setStateOnDelete(biz ,this._data);
                this.dbService.deleteByKey('dolgozok', dolgozo.modul_id).subscribe();
              }
            })
          }
          this.dbService.count('dolgozok').subscribe(count => {
            localStorage.setItem('dolgozok', JSON.stringify({db: count, lastFetch: this.lastFetch}))
          })
        }
      }
    }
    );
  }
}
