import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Location } from '@angular/common';
import { Observable, Subject, of } from 'rxjs';
import {
  OnelszamoloEgyseg,
} from '../../models/torzsek';
import { DijbekeroElolegKimenoHelperService } from '../dijbekero-eloleg-kimeno/dijbekero-eloleg-kimeno-helper.service';
import { AnalitikaService } from '../../services/analitika.service';
import { catchError, take, tap } from 'rxjs/operators';
import { CustomToastService } from '@core/services/custom-toast.service';
import { OnelszamoloEgysegekService } from '../../services/onelszamolo-egysegek.service';
import { FlatpickrOptions } from 'ng2-flatpickr';
import Hungarian from 'flatpickr/dist/l10n/hu.js';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreSidebarComponent } from '@core/components/core-sidebar/core-sidebar.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-analitika',
  templateUrl: './analitika.component.html',
  styleUrls: ['./analitika.component.scss'],
  providers: [DijbekeroElolegKimenoHelperService],
})
export class AnalitikaComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject();
  public penztarNap = new Date();
  public oe: any;
  readonly now = new Date();
  oek$: Observable<OnelszamoloEgyseg[]>;
  public dateRange: any = [];
  public tab: any;

  @ViewChild('coreSidebar') coreSidebar: CoreSidebarComponent;

  public DateRangeOptions: FlatpickrOptions = {
    altInput: true,
    mode: 'range',
    locale: Hungarian.hu,
    dateFormat: 'Y-m-d',
    altFormat: 'Y-m-d',
    allowInput: false,
    onChange: ( selectedDates: any ) => {
       this.dateRange = selectedDates;
      }
  }

  public arajanlatYear = new Date();
  public jutalekYear = new Date();
  public koltsegnemYear = new Date();
  public bizonylatYear = new Date();
  public teljesitettsegYear = new Date();
  public afaDate = new Date();
  public kiegyenlitettDate = new Date();

  public openSidebar: boolean = true;

  constructor(
    private location: Location,
    private analitikaService: AnalitikaService,
    private toastr: CustomToastService,
    private oekService: OnelszamoloEgysegekService,
    private _coreSidebarService: CoreSidebarService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    this.oek$ = this.oekService.data$;
    this.oe = user?.oe;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    if (this.openSidebar) {
      this._coreSidebarService.getSidebarRegistry('analitikaSidebar').close();
    }
  }

  onFoglalasLista(): void {
    const raktarID = JSON.parse(localStorage.getItem('currentUser')).raktar;

    this.analitikaService.generateFoglalasLista(raktarID).pipe(
      tap((url: string) => {
        if (url) {
          // Open preview
          window.open(url, '_blank');
        }
      }),
      take(1),
      catchError((err: any) => {
        console.log(err);
        this.toastr.toastrCustomWarning(
          'Hiba az XLS generálás közben. Kérlek próbáld újra!'
        );

        return of(null);
      })
    ).subscribe();
  }

  onKeszletkarton(): void {
    this.analitikaService.generateKeszletkarton().pipe(
      tap((url: string) => {
        if (url) {
          // Open preview
          window.open(url, '_blank');
        }
      }),
      take(1),
      catchError((err: any) => {
        console.log(err);
        this.toastr.toastrCustomWarning(
          'Hiba az XLS generálás közben. Kérlek próbáld újra!'
        );

        return of(null);
      })
    ).subscribe();
  }

  onVevoAnalitika(): void {
    this.analitikaService.generateVevoAnalitika().pipe(
      tap((url: string) => {
        if (url) {
          // Open preview
          window.open(url, '_blank');
        }
      }),
      take(1),
      catchError((err: any) => {
        console.log(err);
        this.toastr.toastrCustomWarning(
          'Hiba az XLS generálás közben. Kérlek próbáld újra!'
        );

        return of(null);
      })
    ).subscribe();
  }

  onSzallitoAnalitika(): void {
    const startDate = this.formatDate(this.dateRange[0]);
    const endDate = this.formatDate(this.dateRange[1]);

    this.analitikaService.generateSzallitoAnalitika(startDate, endDate).pipe(
      tap((url: string) => {
        if (url) {
          // Open preview
          window.open(url, '_blank');
        }
      }),
      take(1),
      catchError((err: any) => {
        console.log(err);
        this.toastr.toastrCustomWarning(
          'Hiba az XLS generálás közben. Kérlek próbáld újra!'
        );

        return of(null);
      })
    ).subscribe();
  }

  onMinKeszlet(): void {
    const raktarID = JSON.parse(localStorage.getItem('currentUser')).raktar;

    this.analitikaService.generateMinKeszlet(raktarID).pipe(
      tap((url: string) => {
        if (url) {
          // Open preview
          window.open(url, '_blank');
        }
      }),
      take(1),
      catchError((err: any) => {
        console.log(err);
        this.toastr.toastrCustomWarning(
          'Hiba az XLS generálás közben. Kérlek próbáld újra!'
        );

        return of(null);
      })
    ).subscribe();
  }

  onBizonylatAnalitika(): void {
    this.analitikaService.generateBizonylatAnalitika(this.bizonylatYear.getFullYear()).pipe(
      tap((url: string) => {
        if (url) {
          // Open preview
          window.open(url, '_blank');
        }
      }),
      take(1),
      catchError((err: any) => {
        console.log(err);
        this.toastr.toastrCustomWarning(
          'Hiba az XLS generálás közben. Kérlek próbáld újra!'
        );

        return of(null);
      })
    ).subscribe();
  }

  onBejovoBizonylatAnalitika(): void {
    this.analitikaService.generateBejovoBizonylatAnalitika(this.bizonylatYear.getFullYear()).pipe(
      tap((url: string) => {
        if (url) {
          // Open preview
          window.open(url, '_blank');
        }
      }),
      take(1),
      catchError((err: any) => {
        console.log(err);
        this.toastr.toastrCustomWarning(
          'Hiba az XLS generálás közben. Kérlek próbáld újra!'
        );

        return of(null);
      })
    ).subscribe();
  }

  onPenztarZaras(): void {
    this.analitikaService.generateNapiPenztarZaras(this.penztarNap.toISOString(), this.oe).pipe(
      tap((url: string) => {
        if (url) {
          // Open preview
          window.open(url, '_blank');
        }
      }),
      take(1),
      catchError((err: any) => {
        console.log(err);
        this.toastr.toastrCustomWarning(
          'Hiba az XLS generálás közben. Kérlek próbáld újra!'
        );

        return of(null);
      })
    ).subscribe();
  }

  onBizonylatTeljesitett(): void {
    this.analitikaService.generateBizonylatokTeljesitettsege(this.teljesitettsegYear.getFullYear()).pipe(
      tap((url: string) => {
        if (url) {
          // Open preview
          window.open(url, '_blank');
        }
      }),
      take(1),
      catchError((err: any) => {
        console.log(err);
        this.toastr.toastrCustomWarning(
          'Hiba az XLS generálás közben. Kérlek próbáld újra!'
        );

        return of(null);
      })
    ).subscribe();
  }

  onKoltsegnemAnalitika(): void {
    this.analitikaService.generateKoltsegnemAnalitika(this.koltsegnemYear.getFullYear()).pipe(
      tap((url: string) => {
        if (url) {
          // Open preview
          window.open(url, '_blank');
        }
      }),
      take(1),
      catchError((err: any) => {
        console.log(err);
        this.toastr.toastrCustomWarning(
          'Hiba az XLS generálás közben. Kérlek próbáld újra!'
        );

        return of(null);
      })
    ).subscribe();
  }

  onForgalomAnalitika(): void {
    const startDate = this.formatDate(this.dateRange[0]);
    const endDate = this.formatDate(this.dateRange[1]);
    
    this.analitikaService.generateForgalomAnalitika(startDate, endDate).pipe(
      tap((url: string) => {
        if (url) {
          // Open preview
          window.open(url, '_blank');
        }
      }),
      take(1),
      catchError((err: any) => {
        console.log(err);
        this.toastr.toastrCustomWarning(
          'Hiba az XLS generálás közben. Kérlek próbáld újra!'
        );

        return of(null);
      })
    ).subscribe();
  }

  onJutalek(): void {
    this.analitikaService.generateJutalekAnalitika(this.jutalekYear.getFullYear()).pipe(
      tap((url: string) => {
        if (url) {
          // Open preview
          window.open(url, '_blank');
        }
      }),
      take(1),
      catchError((err: any) => {
        console.log(err);
        this.toastr.toastrCustomWarning(
          'Hiba az XLS generálás közben. Kérlek próbáld újra!'
        );

        return of(null);
      })
    ).subscribe();
  }

  onKiegyenlitettSzamlak(): void {
    const ev = this.kiegyenlitettDate.getFullYear();
    const honap = this.kiegyenlitettDate.getMonth() + 1;
    this.analitikaService.generateKimenoSzamlaKifizetesekAnalitika(ev, honap).pipe(
      tap((url: string) => {
        if (url) {
          // Open preview
          window.open(url, '_blank');
        }
      }),
      take(1),
      catchError((err: any) => {
        console.log(err);
        this.toastr.toastrCustomWarning(
          'Hiba az XLS generálás közben. Kérlek próbáld újra!'
        );

        return of(null);
      })
    ).subscribe();
  }

  onAfaAnalitika(): void {
    const startDate = this.formatDate(this.dateRange[0]);
    const endDate = this.formatDate(this.dateRange[1]);
    
    this.analitikaService.generateAfaAnalitikaIntervall(startDate, endDate).pipe(
      tap((url: string) => {
        if (url) {
          // Open preview
          window.open(url, '_blank');
        }
      }),
      take(1),
      catchError((err: any) => {
        console.log(err);
        this.toastr.toastrCustomWarning(
          'Hiba az XLS generálás közben. Kérlek próbáld újra!'
        );

        return of(null);
      })
    ).subscribe();
    // this.analitikaService.generateAfaAnalitika(
    //   this.afaDate.getFullYear(),
    //   this.afaDate.getMonth() + 1
    // ).pipe(
    //   tap((url: string) => {
    //     if (url) {
    //       // Open preview
    //       window.open(url, '_blank');
    //     }
    //   }),
    //   take(1),
    //   catchError((err: any) => {
    //     console.log(err);
    //     this.toastr.toastrCustomWarning(
    //       'Hiba az XLS generálás közben. Kérlek próbáld újra!'
    //     );

    //     return of(null);
    //   })
    // ).subscribe();
  }

  onArajanlatAnalitika(): void {
    this.analitikaService.generateArajanlatAnalitika(this.arajanlatYear.getFullYear()).pipe(
      tap((url: string) => {
        if (url) {
          // Open preview
          window.open(url, '_blank');
        }
      }),
      take(1),
      catchError((err: any) => {
        console.log(err);
        this.toastr.toastrCustomWarning(
          'Hiba az XLS generálás közben. Kérlek próbáld újra!'
        );

        return of(null);
      })
    ).subscribe();
  }

  onSubmit(): void {

  }

  onKintlevosegekOpen(): any {
    this.router.navigate(['torzsek/kintlevosegek']);
    this.closeSidebar();
  }

  closeSidebar(): void {
    this._coreSidebarService.getSidebarRegistry('analitikaSidebar').close();
  }

  navigateToList(): void {
    this.location.back();
  }

  // 👇️ format as "YYYY-MM-DD"
// You can tweak the format easily
formatDate(date: Date): any {
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-')
  );

  // ✅ Defining a reusable function
function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0');
}
}

}
