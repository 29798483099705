import { AbstractControl, FormGroup } from '@angular/forms';

export const addDays = (date: Date, days: number) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const convertBoolean = (value: 0 | 1): boolean => (value ? true : false);

export const calcSum = (
  tetelek: any[],
  skipSztorno?: boolean
): {
  netto_ar: number;
  brutto_ar: number;
  afa_ar: number;
} => {
  let netto_ar = 0;
  let brutto_ar = 0;
  let afa_ar = 0;

  tetelek.forEach((t: any) => {
    if (skipSztorno && t.statusz === -1) {
      return;
    }

    netto_ar += t.netto_ar;
    brutto_ar += t.brutto_ar;
    afa_ar += t.afa_ar;
  });

  return {
    netto_ar,
    brutto_ar,
    afa_ar,
  };
};

export const calcKintlevosegekSum = (
  tetelek: any[],
  skipSztorno?: boolean
): {
  brutto_forint: number;
  brutto_eur: number;
} => {
  let brutto_forint = 0;
  let brutto_eur = 0;

  tetelek.forEach((t: any) => {
    if (skipSztorno && t.statusz === -1) {
      return;
    }
    if(t.penznem == 'Ft') {
      brutto_forint += t.fizetendo;
    } else {
      brutto_eur += t.fizetendo;
    }
  });

  return {
    brutto_forint,
    brutto_eur
  };
};

export const calcPenztarSum = (
  tetelek: any[],
  skipSztorno?: boolean
): {
  kiadasok: number;
  bevetelek: number;
  egyenleg: number;
  penztar: number;
} => {
  let kiadasok = 0;
  let bevetelek = 0;
  let egyenleg = 0;
  let penztar = 1;

  tetelek.forEach((t: any) => {
    if (skipSztorno && t.statusz === -1) {
      return;
    }

    if(t.bevetel_kiadas == 'bevetel') {
      bevetelek += t.osszeg;
    } else if(t.bevetel_kiadas == 'kiadas') {
      kiadasok += t.osszeg;
    }

    penztar = t.penztar_id;

  });

  return {
    kiadasok,
    bevetelek,
    egyenleg: bevetelek + kiadasok,
    penztar
  };
};

export const generateId = (): string =>
  '_' + Math.random().toString(36).slice(2, 9);

export const updateObjInArray = (
  arr: any[],
  index: number,
  updated: any
): any[] =>
  arr.map((e: any, i: number) => {
    if (i === index) {
      return updated;
    }

    return e;
  });

export const updateObjPropInArray = (
  arr: any[],
  index: number,
  updated: any
): any[] =>
  arr.map((e: any, i: number) => {
    if (i === index) {
      return { ...e, ...updated };
    }

    return e;
  });

export function isRequired(formGroup: FormGroup, controlName: string): boolean {
  const control: AbstractControl = formGroup.get(controlName);

  if (!control) {
    return false;
  }

  if (control.validator) {
    const validator = control.validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
  }
}

export const findByProp = (
  data: any[],
  pName: string,
  pValue: string | number | boolean
): any => {
  if (!data || !pName || !pValue) {
    return null;
  }

  return data.find((e: any) => e[pName] === pValue);
};

export const parseCim = (cim: any): string => {
  if (!cim) {
    return '-';
  } else {
    return `${cim?.iranyitoszam || ''} ${cim?.telepules || ''} ${
      cim?.kozterulet_neve || ''
    } ${cim?.kozterulet_tipusa || ''} ${cim?.hazszam || ''} ${
      cim?.epulet || ''
    } ${cim?.emelet || ''} ${cim?.ajto || ''}`;
  }
};

export const findInvalidControls = (formControls: {
  [key: string]: AbstractControl;
}): void => {
  const invalid = [];
  for (const name in formControls) {
    if (formControls[name].invalid) {
      invalid.push(name);
    }
  }

  console.log(invalid);
};

export const parseJSON = (str: string | any): any =>
  isJSONString(str) ? JSON.parse(str) : str;

export const isJSONString = (str: string): any => {
  if (Array.isArray(str)) {
    return false;
  }

  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const stringifyIfJSON = (str: string | any): any => {
  if(str == null) {
    return null;
  }
  if(typeof str === 'string') {
    return str;
  } else {
    return JSON.stringify(str);
  }
}

export const calcBrutto = (netto: number, afakulcs: number): number =>
  afakulcs ? +(netto + (netto * afakulcs) / 100).toFixed(2) : +netto.toFixed(2);

export const calcNetto = (brutto: number, afakulcs: number): number =>
  afakulcs
    ? +(brutto / ((100 + afakulcs) / 100)).toFixed(2)
    : +brutto?.toFixed(2);

export const calcNettoFromAfa = (afa: number, afakulcs: number): number =>
  afakulcs ? +(afa / (afakulcs / 100)).toFixed(2) : 0.0;

export const roundToMaxTwoDecimals = (num: number): number =>
  Math.round(num * 100) / 100;

export function roundNearest5(num) {
    return Math.round(num / 5) * 5;
  }

export function roundtoN(num, n) {
  return Number(num.toFixed(n));
}

export function filterDataByYear(data: any, year: number) {
  return data.filter(bizonylat => {
    if(bizonylat.szamla_kelte) {
      return new Date(bizonylat.szamla_kelte).getFullYear() == year;
    } else if(bizonylat.bizonylat_datuma) {
      return new Date(bizonylat.bizonylat_datuma).getFullYear() == year;
    } else {
      if(bizonylat.teljesites_datum) {
        return new Date(bizonylat.teljesites_datum).getFullYear() == year;
      } else {
        return false;
      }
    }
  });
}

export function sumByProp(array: any[], prop: string) {
  return array.reduce((a, b) => a + (b[prop] || 0), 0);
}

export function roundToTwoDecimalPlaces(num: number): number {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

export function getDateDifferenceInDays(date1: string | Date, date2: string | Date): number {
  // Convert inputs to Date objects if they are strings
  const d1 = typeof date1 === 'string' ? new Date(date1) : date1;
  const d2 = typeof date2 === 'string' ? new Date(date2) : date2;

  // Get time difference in milliseconds
  const timeDiff = Math.abs(d2.getTime() - d1.getTime());

  // Convert milliseconds to days
  return Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
}

export function getHungarianMonth(monthNumber: number): string {
  const months: string[] = [
    "Január", "Február", "Március", "Április", "Május", "Június",
    "Július", "Augusztus", "Szeptember", "Október", "November", "December"
  ];

  if (monthNumber < 1 || monthNumber > 12) {
    throw new Error("Hibás hónap szám! (Érvényes tartomány: 1-12)");
  }

  return months[monthNumber - 1];
}

